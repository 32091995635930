<template>
  <b-navbar type="light" toggleable="md" fixed="top">
    <!-- Header with logos -->
    <div class="navbar-header navbar-light d-none d-md-flex align-items-md-center">
      

      <div class="navbar-brand navbar-brand-md">
				<router-link :to="{name : 'Dashboard'}" class="d-inline-block">
					<img :src="assetLocal('global_assets/images/logo-rsh-wide.png')" alt="RSH Logo">
				</router-link>
			</div>
			
			<div class="navbar-brand navbar-brand-xs">
				<router-link :to="{name : 'Dashboard'}" class="d-inline-block">
					<img :src="assetLocal('global_assets/images/logo-rsh-mark.png')" style="height:auto; width: 2rem;" alt="RSH Logo">
				</router-link>
			</div>
    </div>
    
      
    <div style="margin-top: 22px;width:20px;" v-if="user.levelId == uPerawat || user.levelId == uDokter || user.levelId == uDokterFisio || user.levelId == uAdmisi" @click="getUnit(true)">
      <i v-b-tooltip.hover="'Ubah Unit'" class="icon-cog"></i>
    </div>
    <!-- /header with logos -->

    <!-- Mobile controls -->
    <div class="d-flex flex-1 d-md-none">
			<div class="navbar-brand mr-auto">
				<router-link :to="{name : 'Dashboard'}" class="d-inline-block">
					<img :src="assetLocal('img/logo-dark.png')" alt="">
				</router-link>
			</div>	

			<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-mobile">
				<i class="icon-tree5"></i>
			</button>

			<button class="navbar-toggler sidebar-mobile-main-toggle" type="button">
				<i class="icon-paragraph-justify3"></i>
			</button>
    </div>
    <!-- /mobile controls -->


    <!-- Navbar content -->
    <b-collapse is-nav id="navbar-mobile">
      <b-navbar-nav class="align-items-center">
        <b-nav-item
          link-classes="navbar-nav-link sidebar-control sidebar-main-toggle d-none d-md-block"
          @click="toggleSidebarDesktop"
        >
          <i class="icon-paragraph-justify3"></i>
        </b-nav-item>
      </b-navbar-nav>
      <div class="ml-md-3 mr-md-auto"> 
      <span v-if="isOnline" class="badge bg-success-400 badge-pill ml-md-3 mr-md-auto">online</span> 
      <span v-else class="badge bg-danger-400 badge-pill ml-md-3 mr-md-auto">offline</span> 
      
      <span class="ml-md-2 d-inline-block mb-0"> {{dateTime || getDate}} </span></div>
      
      <b-navbar-nav class="align-items-center">
        <b-nav-item-dropdown right toggle-class="navbar-nav-link d-flex align-items-center" menu-class="wmin-md-350 py-0" no-caret>
          <template #button-content>
            <i class="icon-bell3"></i>
            <span class="d-md-none ml-2">Messages</span>
            <span  v-if="(notificationList.data||[]).findIndex(x=>x.an_status == 'D') !== -1" class="badge badge-mark border-pink-400 ml-auto ml-md-0"></span>
          </template>
          <div class="dropdown-content-header">
            <span class="font-weight-semibold">Messages</span>
          </div>
          <div class="dropdown-content-body dropdown-scrollable">
            <ul class="media-list" v-if="(notificationList.data||[]).length">
              <li class="media" v-for="(v,k) in (notificationList.data||[])" :key="k">
                <template>
                  <div class="mr-3 position-relative">
                    <img :src="uploader(v.bu_avatar)" width="36" height="36" class="rounded-circle" alt="">
                  </div>
                  <div class="media-body">
                    <div class="media-title">
                      <template v-if="v.an_more_opt == 'kontrol'">
                        <router-link :to="{ name: 'RoRencanaKontrol', params: { pageSlug: v.arrk_id}}" class="font-weight-semibold">
                        {{v.bu_full_name||""}}
                        </router-link>
                        <span class="text-muted float-right font-size-sm">{{momentDate(v.an_created_date)}}</span>
                      </template>
                      <template v-else>
                        
                        <router-link :to="{ name: 'RekamMedis', params: { pageSlug: v.an_pasien_id }, query: {regId: v.an_ar_id} }" v-if="v.an_pasien_id && v.an_ar_id" class="font-weight-semibold">
                         {{v.bu_full_name||""}}
                        </router-link>

                        <router-link :to="{ name: 'RoReservasi', params: { pageSlug: v.an_res_id}, query: {isDetail: true} }" v-if="v.an_res_id" class="font-weight-semibold">
                        {{v.bu_full_name||""}}
                        </router-link>
                        <span class="text-muted float-right font-size-sm">{{momentDate(v.an_created_date)}}</span>
                      </template>
                    </div>
                    <span class="text-muted">{{v.an_message}}</span>
                    <span class="text-muted">
                        <span :class="v.an_status == 'D'?'text-muted float-right font-size-sm mt-3':'text-info float-right font-size-sm mt-3'">{{v.an_status == 'D' ? 'Belum Dibaca' : 'Sudah Dibaca'}}</span>
                    </span>
                  </div>
                </template>
							</li>
            </ul>
            <span v-else>No Messages Found</span>
          </div>
          <div class="dropdown-content-footer bg-light">
            <router-link :to="{name: 'Notification'}" v-if="(notificationList.data||[]).length"
              class="text-grey mr-auto" >All messages</router-link>
          </div>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown right toggle-class="navbar-nav-link d-flex align-items-center">
          <template #button-content>
            <b-img
              :src="uploader(user.avatar)"
              class="mr-2"
              rounded="circle"
              height="34"
              alt=""
            />
            <span>{{ user.fullName }}</span>
          </template>
          <b-dropdown-item :to="{name: 'AccountSetting'}">
            <i class="icon-cog5"></i> Account settings
          </b-dropdown-item>
          <b-dropdown-item @click="doLogout">
            <i class="icon-switch2"></i> Logout
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>

    
    <validation-observer ref="VFormSetUnit">
    <b-modal v-model="modalOpenUnit" :title="'Unit Tujuan'" size="sm" @ok.prevent="submitChangeUnit">
        <div class="row">
            <div class="form-group col-md-12">
                <label> Pilih Unit Tujuan <small class="txt_mandatory">*</small></label>
                
                <v-select placeholder="Pilih Unit Tujuan" v-model="dispData.arud_unit"
                  :options="mUnit" label="text" :clearable="true"
                  :reduce="v=>v.value"></v-select>

                <VValidate 
                    name="Unit Tujuan" 
                    v-model="dispData.arud_unit" 
                    :rules="{required: 1}"
                />
            </div>
        </div>
    </b-modal>
    </validation-observer>
  </b-navbar>
  
  
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default {
  extends: GlobalVue,
  data() {
    return {
      notificationList: [],
      dateTime : null,
      isOnline : true,
      dispData : {},
      isHave : true,
      modalOpenUnit : false,
      mUnit : [],
    }
  },
  mounted() {
    // setInterval(()=>{
    //   this.dateTime = moment().format('dddd, Do MMMM YYYY, HH:mm:ss')
    // },1000)
    
    if(process.env.VUE_APP_MODE != 'local'){
      setInterval(()=>{
        this.checkOnlineStatus()
      },5000)
    }
    this.getNotification()
    this.getUnit()
  },
  watch:{
    $route(){
      this.getNotification()
    }
  },
  computed: {
      getDate(){
        return moment().format('dddd, Do MMMM YYYY, HH:mm:ss')
      }
  },
  methods: {
    submitChangeUnit(){
      let data = this.dispData
      this.$refs['VFormSetUnit'].validate().then(success=>{
        if(success){
          this.loadingOverlay = true
          data.type = "set-unit-users"
          Gen.apiRest(
            "/do/" + 'RoUsersDisplay', {
              data: data
            },
            "POST"
          ).then(res => {
            this.loadingOverlay = false
            let resp = res.data
            resp.statusType = 200
            
            this.$socket.emit('refresh_data', {to : this.uPerawat})
            this.$socket.emit('refresh_data', {to : this.uDokter})
            this.$socket.emit('refresh_data', {to : this.uDokterFisio})
            this.$socket.emit('refresh_data', {to : this.uAdmisiUGD})
            
          
            this.$swal({
              title: resp.message,
              icon: resp.status,
              confirmButtonText: 'Ok',
              allowOutsideClick: false,
              allowEscapeKey: false
            }).then(result => {
              if (result.value) {
                this.modalOpenUnit = false
              }
            })
          })
        }
      })
    },
    getUnit(force=false){
      console.log("sini")
      if(this.user.levelId == this.uPerawat || this.user.levelId == this.uDokter || this.user.levelId == this.uDokterFisio || this.user.levelId == this.uAdmisi){
          
        Gen.apiRest(
          "/get/"+"RoUsersDisplay"
        ).then(res=>{
          if(!res.data.isHave || force){
            this.modalOpenUnit = true
            this.mUnit = res.data.mUnit
            this.dispData = res.data.dispData
          }
        })
      }
    },
    getNotification(){
      Gen.apiRest(
        "/get/"+"Notification",
        {
          params: {
            id : parseInt(this.user.id),
            levelId : parseInt(this.user.levelId),
          }
        }
      ).then(res=>{
        this.notificationList = res.data.data
      })
    },
    momentDate(dates){
      let formatted = moment(dates).format("YYYYMMDDHHmm")
      let newDates = moment(formatted, "YYYYMMDDHHmm").fromNow()
      return newDates
    },
    toggleSidebarDesktop() {
      document.querySelector('body').classList.toggle('sidebar-xs')
    },
    logout() {
      this.$store.commit('unsetAuth')
      this.$router.push({ name: 'BoAuth' }).catch(()=>{})
    },
    getTime(){
      this.dateTime = moment().format('dddd, Do MMMM YYYY, HH:mm:ss')
    },
    async checkOnlineStatus (){
      try {
        const online = await fetch("https://jsonplaceholder.typicode.com/posts")
        this.isOnline = true
      } catch (err) {
        this.isOnline = false
        return false // definitely offline
      }
    }
  },
}
</script>