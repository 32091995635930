import _ from 'lodash'

const menu = [
  {
    cap: 'Main',
    relate: [
      'Dashboard',
      'KeselamatanPasien',
      'RegistrasiPasien',
      'Hais',
      'LaporanInsiden',
      'Dokter',
      'RoPasien',
      'LaporanManagement',
      'RoReportAdmisi',
      'RoJadwalDokter',
      'AppRanapJadwalOperasi',

      //ugd
      'UGDReportAdmisi',
      'UGDTriase',
      'UGDAssesmentDokter',
      'UGDAssesmentPerawat',
      'UGDTindakLanjut',
      'UGDAssesmenRadiologi',
      'UGDAssesmenLab',
      'UGDFarmasi',
      'UGDRekonsiliasi',
      'UGDRekamMedis',
      'UGDUPLA',
      'UGDRujukanKeluar',
      'UGDRujukanMasuk',
      'UGDPenerbitanSurat',
      'UGDSuratEmergency',
      'UGDHais',
      'UGDPasienRanap'

    ]
  },
  {
    title: 'Dashboard',
    route: {name: 'Dashboard'},
    menu: 'Dashboard',
    icon: 'icon-home4',
  },
  {
    title: 'Laporan Management',
    route: {
      name: 'LaporanManagement'
    },
    menu: 'LaporanManagement',
    icon: 'icon-file-text',
  },
  {
    title: 'Report Admisi',
    route: {
      name: 'RoReportAdmisi'
    },
    menu: 'RoReportAdmisi',
    icon: 'icon-file-text',
  }, 
  
  {
    title: 'Resiko Jatuh',
    route: {name: 'KeselamatanPasien'},
    menu: 'KeselamatanPasien',
    icon: 'icon-shield2',
  },
  {
    title: 'Registrasi Pasien',
    route: {
      name: 'RegistrasiPasien'
    },
    menu: 'RegistrasiPasien',
    icon: 'icon-list',
  },
  {
    title: 'HAIs',
    route: {name: 'Hais'},
    menu: 'Hais',
    icon: 'icon-notification2',
  },
  {
    title: 'Laporan Insiden',
    route: {name: 'LaporanInsiden'},
    menu: 'LaporanInsiden',
    icon: 'icon-file-text3',
  },
  {
    title: 'Dokter',
    route: {name: 'Dokter'},
    menu: 'Dokter',
    icon: 'icon-briefcase',
  },
  {
    title: 'Pasien',
    route: {name: 'RoPasien'},
    menu: 'RoPasien',
    icon: 'icon-users',
  },
  {
    title: 'JadwalDokter',
    route: {
      name: 'RoJadwalDokter'
    },
    menu: 'RoJadwalDokter',
    icon: 'icon-calendar',
  },
  {
    title: 'Jadwal Operasi',
    route: {
      name: 'AppRanapJadwalOperasi'
    },
    menu: 'AppRanapJadwalOperasi',
    icon: 'icon-calendar',
  },

  //ugd
  {
    title: 'Report UGD',
    route: {
      name: 'UGDReportAdmisi'
    },
    menu: 'UGDReportAdmisi',
    icon: 'icon-file-text',
  },
  {
    title: 'Triase UGD',
    route: {
      name: 'UGDTriase'
    },
    menu: 'UGDTriase',
    icon: 'icon-gradient',
  },
  {
    title: 'Kajian Perawat UGD',
    route: {
      name: 'UGDAssesmentPerawat'
    },
    menu: 'UGDAssesmentPerawat',
    icon: 'icon-notification2',
  },
  {
    title: 'Kajian Dokter UGD',
    route: {
      name: 'UGDAssesmentDokter'
    },
    menu: 'UGDAssesmentDokter',
    icon: 'icon-notification2',
  },
  {
    title: 'Tindak Lanjut',
    route: {
      name: 'UGDTindakLanjut'
    },
    menu: 'UGDTindakLanjut',
    icon: 'icon-notification2',
  },
  {
    title: 'UGD Laboratorium',
    route: {
      name: 'UGDAssesmenLab'
    },
    menu: 'UGDAssesmenLab',
    icon: 'icon-home4',
  },
  {
    title: 'UGD Radiologi',
    route: {
      name: 'UGDAssesmenRadiologi'
    },
    menu: 'UGDAssesmenRadiologi',
    icon: 'icon-home4',
  },
  {
    title: 'UGD Farmasi',
    route: {
      name: 'UGDFarmasi'
    },
    menu: 'UGDFarmasi',
    icon: 'icon-home4',
  },
  {
    title: 'Rekonsiliasi Obat',
    route: {
      name: 'UGDRekonsiliasi'
    },
    menu: 'UGDRekonsiliasi',
    icon: 'icon-stack-text',
  },
  {
    title: 'UGD Rekam Medis',
    route: {name: 'UGDRekamMedis'},
    menu: 'UGDRekamMedis',
    icon: 'icon-home4',
  },
  {
    title: 'UGD UPLA',
    route: {name: 'UGDUPLA'},
    menu: 'UGDUPLA',
    icon: 'icon-home4',
  },
  {
    title: 'UGD Rujukan',
    icon: 'icon-file-text3',
    children: [
      {
        title: 'Rujukan Masuk',
        route: { name: 'UGDRujukanMasuk' },
        menu: 'UGDRujukanMasuk'
      },
      {
        title: 'Rujukan Keluar',
        route: { name: 'UGDRujukanKeluar' },
        menu: 'UGDRujukanKeluar'
      },
    ]
  },
  {
    title: 'Penerbitan Surat',
    route: {name: 'UGDPenerbitanSurat'},
    menu: 'UGDPenerbitanSurat',
    icon: 'icon-bookmarks',
  },
  {
    title: 'Surat Emergency',
    route: {
      name: 'UGDSuratEmergency'
    },
    menu: 'UGDSuratEmergency',
    icon: 'icon-file-text3',
  },
  {
    title: 'Hais UGD',
    route: {
      name: 'UGDHais'
    },
    menu: 'UGDHais',
    icon: 'icon-notification2',
  },
  {
    title: 'Pasien Rawat Inap',
    route: {
      name: 'UGDPasienRanap'
    },
    menu: 'UGDPasienRanap',
    icon: 'icon-notification2',
  },
  {
    cap: 'Admisi',
    relate: [
      'RoReservasi',
      'RoRujukan',
      'RoSuratKontrol',
      'RoAdmisiAntrean',
      'UGDReservasi', // UGD
      'UGDAdmisiAntrean', // UGD       
    ]
  },
  {
    title: 'Antrean',
    route: {
      name: 'RoAdmisiAntrean'
    },
    menu: 'RoAdmisiAntrean',
    icon: 'icon-list',
  },
  {
    title: 'Reservasi',
    route: {
      name: 'RoReservasi'
    },
    menu: 'RoReservasi',
    icon: 'icon-bookmarks',
  },

  // UGD
  {
    title: 'Pendaftaran UGD',
    route: {
      name: 'UGDReservasi'
    },
    menu: 'UGDReservasi',
    icon: 'icon-bookmarks',
  },
  {
    title: 'Antrean UGD',
    route: {
      name: 'UGDAdmisiAntrean'
    },
    menu: 'UGDAdmisiAntrean',
    icon: 'icon-list',
  },
  {
    title: 'Rujukan',
    route: {
      name: 'RoRujukan'
    },
    menu: 'RoRujukan',
    icon: 'icon-file-text3',
  }, 
  {
    title: 'Rencana Kontrol',
    route: {
      name: 'RoRencanaKontrol'
    },
    menu: 'RoRencanaKontrol',
    icon: 'icon-file-text3',
  }, 

  {
    cap: 'Billing',
    relate: [
      'RoBilling',
    ]
  }, 

  {
    title: 'Billing',
    route: {
      name: 'RoBilling'
    },
    menu: 'RoBilling',
    icon: 'icon-clipboard',
  },

  {
    cap: 'Kasir',
    relate: [
      'RoKasir',
    ]
  },

  {
    title: 'Kasir',
    route: {
      name: 'RoKasir'
    },
    menu: 'RoKasir',
    icon: 'icon-credit-card',
  },

  {
    cap: 'Antrean',
    relate: [
      'AntreanRoAudio',
      'AntreanRoUnit',
      'AntreanRoGroup',
      'AntreanRoDisplayLayar',
    ]
  },
  {
    title: 'Antrean',
    icon: 'icon-list',
    children: [
      {
        title: 'Audio Antrean',
        route: { name: 'AntreanRoAudio' },
        menu: 'AntreanRoAudio'
      },
      {
        title: 'Unit Tujuan',
        route: { name: 'AntreanRoUnit' },
        menu: 'AntreanRoUnit'
      },
      {
        title: 'Group Antrian',
        route: { name: 'AntreanRoGroup' },
        menu: 'AntreanRoGroup'
      },
      {
        title: 'Display Layar',
        route: { name: 'AntreanRoDisplayLayar' },
        menu: 'AntreanRoDisplayLayar'
      },
    ]
  },


  {
    cap: 'Other',
    relate: [
      'Users',
      'UserLevel',
      'MAgama',
      'MPekerjaan',
      'MPendidikan',
      'MKategoriSDKI',
      'MSubKategoriSDKI',
      'MSDKI',
      'MSLKI',
      'MKriteriaSLKI',
      'MSIKI',
      'MAlatBantuTarik',
      'MTindakan',
      'MCaraPembayaran',
      'MICD9',
      'MICD10',
      'MICD9UPLA',
      'MICD10UPLA',
      'MPoli',
      'MObat',
      'MAlatKesehatan',
      'MRadiologi',
      'MZipCode',
      'MDosis',
      'MSubLab',
      'MLab',
      'MInsiden',
      'MRoFakesRujukan',
      'MRoPoliRujukan',
      'MRoDokterRujukan',
      'MHariLibur',
      'MRuangan',
      'MUGDRS',
      'MUGDPuskesmas',
    ]
  },
  {
    title: 'Master',
    icon: 'icon-list',
    children: [
      {
        title: 'Aturan Pakai',
        route: { name: 'MDosis' },
        menu: 'MDosis'
      },
      {
        title: 'HAIs',
        route: { name: 'MHais' },
        menu: 'MHais'
      },
      {
        title: 'Kesadaran',
        route: { name: 'MKesadaran' },
        menu: 'MKesadaran'
      },
      {
        title: 'Poli',
        route: { name: 'MPoli' },
        menu: 'MPoli'
      },
      {
        title: 'Obat',
        route: { name: 'MObat' },
        menu: 'MObat'
      },
      {
        title: 'Alat Kesehatan',
        route: { name: 'MAlatKesehatan' },
        menu: 'MAlatKesehatan'
      },
      {
        title: 'Radiologi',
        route: { name: 'MRadiologi' },
        menu: 'MRadiologi'
      },
      {
        title: 'Alat Bantu Tarik',
        route: { name: 'MAlatBantuTarik' },
        menu: 'MAlatBantuTarik'
      },
      {
        title: 'Tindakan Fisioterapi',
        route: { name: 'MTindakan' },
        menu: 'MTindakan'
      },
      {
        title: 'Cara Pembayaran',
        route: { name: 'MCaraPembayaran' },
        menu: 'MCaraPembayaran'
      },
      {
        title: 'Agama',
        route: { name: 'MAgama' },
        menu: 'MAgama'
      },
      {
        title: 'Pekerjaan',
        route: { name: 'MPekerjaan' },
        menu: 'MPekerjaan'
      },
      {
        title: 'Pendidikan',
        route: { name: 'MPendidikan' },
        menu: 'MPendidikan'
      },
      {
        title: 'SDKI',
        children: [
        {
          title: 'Kategori',
          route: {
            name: 'MKategoriSDKI'
          },
          menu: 'MKategoriSDKI'
        },
        {
          title: 'Sub Kategori',
          route: {
            name: 'MSubKategoriSDKI'
          },
          menu: 'MSubKategoriSDKI'
        },
        {
          title: 'Diagnosa',
          route: {
            name: 'MSDKI'
          },
          menu: 'MSDKI'
        }]
      },
      {
        title: 'SLKI',
        children: [
          {
            title: 'Diagnosa',
            route: {
              name: 'MSLKI'
            },
            menu: 'MSLKI'
          },
          {
            title: 'Kriteria Hasil',
            route: {
              name: 'MKriteriaSLKI'
            },
            menu: 'MKriteriaSLKI'
          }
        ]
      },
      {
        title: 'SIKI',
        route: { name: 'MSIKI' },
        menu: 'MSIKI'
      },
      {
        title: 'Diagnosa Gizi',
        route: { name: 'MICDGizi' },
        menu: 'MICDGizi'
      },
      {
        title: 'Intervensi Gizi',
        route: {
          name: 'MICDGiziTindakan'
        },
        menu: 'MICDGiziTindakan'
      },
      {
        title: 'ICD9 UPLA',
        route: { name: 'MICD9UPLA' },
        menu: 'MICD9UPLA'
      },
      {
        title: 'ICD10 UPLA',
        route: { name: 'MICD10UPLA' },
        menu: 'MICD10UPLA'
      },
      {
        title: 'ICD9',
        route: { name: 'MICD9' },
        menu: 'MICD9'
      },
      {
        title: 'ICD10',
        route: { name: 'MICD10' },
        menu: 'MICD10'
      },
      {
        title: 'Insiden',
        route: { name: 'MInsiden' },
        menu: 'MInsiden'
      },
      {
        title: 'Zip Code',
        route: { name: 'MZipCode' },
        menu: 'MZipCode'
      },
      {
        title: 'Laboratorium',
        children: [
        {
          title: 'Sub Kategori',
          route: {
            name: 'MSubLab'
          },
          menu: 'MSubLab'
        },
        {
          title: 'Tindakan',
          route: {
            name: 'MLab'
          },
          menu: 'MLab'
        }]
      },
      {
        title: 'Zip Code',
        route: { name: 'MZipCode' },
        menu: 'MZipCode'
      },
      {
        title: 'Fakes Rujukan',
        children: [{
            title: 'Nama Fakes',
            route: {
              name: 'MRoFakesRujukan'
            },
            menu: 'MRoFakesRujukan'
          },
          {
            title: 'Poli',
            route: {
              name: 'MRoPoliRujukan'
            },
            menu: 'MRoPoliRujukan'
          },
          {
            title: 'Dokter',
            route: {
              name: 'MRoDokterRujukan'
            },
            menu: 'MRoDokterRujukan'
          },
        ]
      },
      {
        title: 'Suku',
        route: { name: 'MRoSuku' },
        menu: 'MRoSuku'
      },
      {
        title: 'Hari Libur',
        route: {
          name: 'MHariLibur'
        },
        menu: 'MHariLibur'
      },
      {
        title: 'Riwayat Alergi',
        route: { name: 'MRiwayatAlergi' },
        menu: 'MRiwayatAlergi'
      },
      {
        title: 'Ruangan Rawat Inap',
        route: {
          name: 'MRuangan'
        },
        menu: 'MRuangan'
      },
      {
        title: 'Asal Rujukan UGD (RS)',
        route: {
          name: 'MUGDRS'
        },
        menu: 'MUGDRS'
      },
      {
        title: 'Asal Rujukan UGD (Puskesmas)',
        route: {
          name: 'MUGDPuskesmas'
        },
        menu: 'MUGDPuskesmas'
      }
    ],
  },
  {
    title: 'User Management',
    icon: 'icon-users',
    children: [
      {
        title: 'User List',
        route: {name: 'Users'},
        menu: 'Users'
      },
      {
        title: 'User Level',
        route: {name: 'UserLevel'},
        menu: 'UserLevel'
      },
    ],
  },
  {
    title: 'Audit Trail',
    route: {
      name: 'AuditTrail'
    },
    icon: 'icon-check',
    menu: 'AuditTrail'
  },
  

  {
    cap: 'BPJS',
    relate: [
      'BPJSPeserta',
      'BPJSReferensi',
      'BPJSMonitoring',
      'BPJSSEP',
      'BPJSPRB',
      'BPJSRencanaKontrol',
      'BPJSDataSEP',
      'BPJSLPK',
      'BPJSLogAntrean',
      'BPJSRefrensiAntrean',
      'RoTaskIDHarian',
      'RoBelumDilayani',
      'BPJSKetersediaanKamar',
    ]
  },
  {
    title: 'BPJS Aplicare',
    icon: 'icon-list',
    children: [{
      title: 'Ketersediaan Kamar',
      route: {
        name: 'BPJSKetersediaanKamar'
      },
      menu: 'BPJSKetersediaanKamar'
    }]
  },
  {
    title: 'BPJS VCLAIM',
    icon: 'icon-list',
    children: [
      {
        title: 'Peserta',
        route: {
          name: 'BPJSPeserta'
        },
        menu: 'BPJSPeserta'
      },
      {
        title: 'Referensi',
        route: {
          name: 'BPJSReferensi'
        },
        menu: 'BPJSReferensi'
      },
      {
        title: 'Monitoring',
        route: {
          name: 'BPJSMonitoring'
        },
        menu: 'BPJSMonitoring'
      },
      {
        title: 'CEK SEP',
        route: {
          name: 'BPJSSEP'
        },
        menu: 'BPJSSEP'
      },
      {
        title: 'Rujukan',
        route: {
          name: 'BPJSRujukan'
        },
        menu: 'BPJSRujukan'
      },
      {
        title: 'PRB',
        route: {
          name: 'BPJSPRB'
        },
        menu: 'BPJSPRB'
      },
      {
        title: 'Rencana Kontrol',
        route: {
          name: 'BPJSRencanaKontrol'
        },
        menu: 'BPJSRencanaKontrol'
      },
      {
        title: 'Data SEP',
        route: {
          name: 'BPJSDataSEP'
        },
        menu: 'BPJSDataSEP'
      },
      {
        title: 'Data LPK',
        route: {
          name: 'BPJSLPK'
        },
        menu: 'BPJSLPK'
      }
    ],
  },
  {
    title: 'BPJS Antrean',
    icon: 'icon-list',
    children: [
      {
        title: 'Referensi Antrean',
        route: {
          name: 'BPJSRefrensiAntrean'
        },
        menu: 'BPJSRefrensiAntrean'
      }, 
      {
        title: 'Log Antrean',
        route: {
          name: 'BPJSLogAntrean'
        },
        menu: 'BPJSLogAntrean'
      }, 
      {
        title: 'Aktifitas Task ID Harian',
        route: {
          name: 'RoTaskIDHarian'
        },
        menu: 'RoTaskIDHarian'
      }, 
      {
        title: 'Aktivitas Antrean',
        route: {
          name: 'RoBelumDilayani'
        },
        menu: 'RoBelumDilayani'
      },

      
    ],
  },

  {
    cap: ' '
  },
  {
    cap: ' '
  },
]

let nav = menu
const accessAll = localStorage.getItem('u_menu_all')
if (accessAll == 'N') {
  let menuRole = JSON.parse(localStorage.getItem('u_menu') || '[]')
  menuRole = _.map(menuRole, v => {
    return v.menu
  })
  
  nav = _.filter(nav, v => {
    if (v.cap) {
      return _.filter(v.relate, v2 => {
        return menuRole.indexOf(v2) > -1
      }).length >= 1
    } 
    else if (v.children) {
      return _.filter(v.children, v2 => {
        return menuRole.indexOf(v2.menu) > -1
      }).length >= 1
    } 
    else {
      return menuRole.indexOf(v.menu) > -1
    }
  })

  nav = _.map(nav, v => {
    if (v.children) {
      v.children = _.filter(v.children, v2 => {
        if((v2.children||[]).length){
          v2.children = _.filter(v2.children, v3 => {
            return menuRole.indexOf(v3.menu) > -1
          })
          if ((v2.children||[]).length) {
            return v2
          }
        }else{
          return menuRole.indexOf(v2.menu) > -1
        }
      })
    }
    return v
  })
}

export default nav