<template>
<div id="wrapper">
  <b-overlay z-index="9999"
    :show="loadingOverlay"
    rounded="sm"
  >
  <bo-header />
  <div class="page-content">
    <bo-sidebar />
    <div class="d-flex flex-column flex-fill overflow-auto">
      <router-view />
      <bo-footer />
    </div>
  </div>
  </b-overlay>  
</div>
</template>
<script>
import GlobalVue from '@/libs/Global'
import BoHeader from '@/components/backend/BoHeader.vue'
import BoSidebar from '@/components/backend/BoSidebar.vue'
import BoFooter from '@/components/backend/BoFooter.vue'

export default {
  extends:GlobalVue,
  components: {
    BoHeader,
    BoSidebar,
    BoFooter,
  }
}
</script>